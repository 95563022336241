import React, {Component} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components";
import ContentContainer from "../components/Core/Containers/ContentContainer"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import ButtonDark from "../components/Core/Buttons/ButtonDark"

const StyledContainer = styled.div`
  padding: 20px 0 130px;
  text-align: center;

  @media (min-width: ${breakpoints.sm}) {
    padding: 30px 30px 130px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 220px 30px 150px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    padding: 280px 30px 200px;
  }

`

const Title = styled.h1`
  ${fonts.garamondRegular};
  font-size: 35px;
  line-height: 1;
  letter-spacing: normal;

  @media (min-width: ${breakpoints.md}) {
    font-size: 50px;
    letter-spacing: -.4px;
  }

`

const Copy = styled.div`
  ${fonts.sofiaPro};
  font-size: 22px;
  line-height: 1.2;
  margin-top: 15px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 30px;
    font-size: 24px;
    line-height: 1.2;

  }
`

const ButtonContainer = styled.div`
  margin-top: 30px;
  a {
    max-width: 280px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 40px;

  }

`

class NotFoundPage extends Component {
  render() {
    return (
      <Layout transparentHeader={true}>
        <Seo title="Page not found."
             description="The page you are looking for has been removed or relocated."/>
             <StyledContainer>
               <ContentContainer>
                  <Title>We were off by a hair.</Title>
                  <Copy>Head back to our homepage and we’ll get it straightened out.</Copy>
                  <ButtonContainer>
                    <ButtonDark href="/">
                      Return home
                    </ButtonDark>
                  </ButtonContainer>
               </ContentContainer>
             </StyledContainer>
      </Layout>
    );
  }
}

export default NotFoundPage;
